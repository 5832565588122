<template>
  <v-container fluid class="pa-6">
    <!-- toolbar -->
    <v-row>
      <v-btn dark color="primary" @click="createTemplate">
        <v-icon small class="mr-2">mdi mdi-plus</v-icon>
        {{ $t("add") }}
      </v-btn>
    </v-row>
    <!-- filter panel -->
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/templates"
            :appSetting="appSettingKey"
            :headers="headers"
            :sort-by="['name']"
            :sort-desc="[false]"
            :beforeCallApi="beforeCallApi"
            :afterCallApi="afterCallApi"
          >
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>

            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold">{{
                $t(`template_name.${item.name}`)
              }}</span>
              <br />
              <span class="caption">{{
                $t(`template_desc.${item.name}`)
              }}</span>
            </template>

            <!-- <template v-slot:item.description="{ item }">
              <strong class="col-name">{{
                $t(`template_desc.${item.name}`)
              }}</strong>
            </template> -->

            <!-- <template v-slot:item.inputParams="{ item }">
              <div style="font-size: 10px">
                {{ item.inputParams | shortenText }}
              </div>
            </template> -->

            <template v-slot:item.createdAt="{ item }">
              <span>
                {{ item.createdAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>

            <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt">
                {{ item.updatedAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$updatedBy | empty }})
              </span>
            </template>

            <template v-slot:item.format="{ item }">
              <v-icon :color="showReportFormatColor(item.format)">
                {{ showReportFormat(item.format) }}
              </v-icon>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark>{{
                $t(item.status.split(".").pop())
              }}</v-chip>
            </template>

            <template v-slot:item.tags="{ item }">
              <ul v-if="item.tags.length">
                <li v-for="(tag, index) in item.tags" :key="index">
                  {{ shortenTag(tag) }}
                </li>
              </ul>
              <div v-else>-</div>
            </template>

            <template v-slot:item.checksum="{ item }">
              <div v-if="item.checksum">
                <GsChecksum :checksum="item.checksum" />
              </div>
              <div v-else>-</div>
            </template>

            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} /
              {{ props.itemsLength }}
            </template>
          </GsDataTable>
        </v-card>
      </v-container>
    </v-row>
    <!-- create/edit template -->
    <v-dialog v-model="dialogEditorVisible" persistent max-width="600px">
      <GsTemplateEditor
        ref="templateEditor"
        @saved="modalSave"
        @cancel="dialogEditorVisible = false"
      />
    </v-dialog>
    <!-- add/edit template notification options -->
    <v-dialog v-model="dialogNotificationsVisible" persistent max-width="600px">
      <v-card v-if="selectedTemplate">
        <v-toolbar color="primary" dark
          >{{ $t("edit_notifications") }} -
          {{ $t(`template_name.${selectedTemplate.name}`) }}</v-toolbar
        >
        <GsTemplateNotifications :templateId="selectedTemplate.templateId" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="mb-3 close-button px-4"
            @click="dialogNotificationsVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add/edit tags options -->
    <v-dialog
      v-if="dialogTagsVisible"
      :value="true"
      persistent
      max-width="600px"
    >
      <GsTemplateTags
        :templateId="selectedTemplate.templateId"
        :templateName="selectedTemplate.name"
        @cancel="dialogTagsVisible = false"
        @saved="
          dialogTagsVisible = false;
          refreshTable();
        "
      />
    </v-dialog>
    <!-- attached files -->
    <v-dialog v-model="dialogAttachedFilesVisible" persistent max-width="800px">
      <v-card v-if="selectedTemplate">
        <v-toolbar color="primary" dark
          >{{ $t("edit_attached_files") }} -
          {{ $t(`template_name.${selectedTemplate.name}`) }}</v-toolbar
        >
        <GsTemplateFilesEditor ref="templateFilesEditor" @saved="modalSave" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mb-3 close-button px-4"
            @click="dialogAttachedFilesVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <GsHelpBubble ref="helpBubble" />
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsTemplateEditor from "@/components/GsTemplateEditor";
import GsTemplateFilesEditor from "@/components/GsTemplateFilesEditor";
import GsTemplateNotifications from "@/components/GsTemplateNotifications";
import GsTemplateTags from "@/components/GsTemplateTags";
import GsHelpBubble from "@/components/GsHelpBubble";
import GsChecksum from "@/components/GsChecksum";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
const { fileSave } = require("@/utils/fileSave.js");

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Templates",
  components: {
    GsTemplateEditor,
    GsTemplateFilesEditor,
    GsTemplateNotifications,
    GsHelpBubble,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
    GsTemplateTags,
    GsChecksum,
  },
  data() {
    return {
      appSettingKey: "templates",
      headers: [
        {
          // text: this.$t("actions"),
          text: "",
          align: "center",
          value: "actions",
          width: 10,
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
          width: 240,
          sortable: true,
        },
        // {
        //   text: this.$t("description"),
        //   value: "description",
        //   width: "15%",
        //   sortable: false
        // },
        {
          text: this.$t("datasource"),
          value: "$calculated.datasourceName",
          width: "8%",
          sortable: false,
        },
        {
          text: this.$t("dataset"),
          value: "$calculated.datasetName",
          width: "8%",
          sortable: false,
        },
        // {
        //   text: this.$t("inputParams"),
        //   value: "inputParams",
        //   width: "15%",
        //   sortable: false
        // },
        {
          text: this.$t("format"),
          value: "format",
          width: "5%",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("status"),
          value: "status",
          width: "5%",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("tags"),
          value: "tags",
          width: "20%",
          sortable: false,
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: "15%",
          sortable: true,
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: "15%",
          sortable: true,
        },
        {
          text: this.$t("checksum"),
          value: "checksum",
          width: "10%",
          sortable: false,
        },
      ],
      dialogEditorVisible: false,
      dialogNotificationsVisible: false,
      dialogTagsVisible: false,
      dialogAttachedFilesVisible: false,
      selectedTemplate: null,
      notificationValues: [],
      filters: [
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
        {
          field: "reportTemplateTags",
          component: "GsAnySelectFilter",
          params: {
            options: [],
          },
        },
        {
          field: "reportTemplateGroupTags",
          component: "GsAnyMultiSelectFilter",
          params: {
            options: [],
          },
          width: "long",
        },
      ],
      initialized: false,
      actions: [
        "download",
        "edit",
        "edit_notifications",
        "edit_attached_files",
        "edit_tags",
        "delete",
      ],
    };
  },
  async mounted() {
    // get "template" tag collection
    this.getTagCollection("template", "role:reportManager:template:");
    // init
    this.initialized = false;
    await this.fillUpFilters();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      const datasourceIds = params.items.map((p) => p.datasourceId);
      const datasourceNames = await this.getDatasourceNamesByIds(datasourceIds);

      const datasetIds = params.items.map((p) => p.datasetId);
      const datasetNames = await this.getDatasetNamesByIds(datasetIds);

      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.datasourceName = datasourceNames[item.datasourceId];
        item.$calculated.datasetName = datasetNames[item.datasetId];
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$updatedBy = item?.updatedBy?.name || "";
      }

      return params;
    },
    async createTemplate() {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateEditor) {
          return;
        }
        await this.$refs.templateEditor.create();
      });
    },
    async download_click(template) {
      const data = template.template;
      const filename = `${template.name}.jrxml`;
      const mime = "application/jrxml";
      fileSave(data, filename, mime);
    },
    async edit_click(template) {
      this.dialogEditorVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateEditor) {
          return;
        }
        await this.$refs.templateEditor.edit(template);
      });
    },
    edit_notifications_click(item) {
      this.selectedTemplate = item;
      this.dialogNotificationsVisible = true;
    },
    edit_tags_click(item) {
      this.selectedTemplate = item;
      this.dialogTagsVisible = true;
    },
    async modalSave(template) {
      this.dialogEditorVisible = false;
      await this.refreshTable();
    },
    async delete_click(template) {
      const confirmText = `${this.$t("delete_modal")}\n\n${template.name}`;
      const confirmResult = confirm(confirmText);
      if (!confirmResult) return;

      const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${template.templateId}`;
      const result = await callAPI({ url, method: "DELETE" });
      if (result.status !== 204) return;
      await this.refreshTable();
    },
    async edit_attached_files_click(item) {
      this.selectedTemplate = item;
      this.dialogAttachedFilesVisible = true;
      this.$nextTick(async () => {
        if (!this.$refs.templateFilesEditor) {
          return;
        }
        await this.$refs.templateFilesEditor.edit(item);
      });
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "download":
          this.download_click(item);
          break;
        case "edit":
          this.edit_click(item);
          break;
        case "edit_notifications":
          this.edit_notifications_click(item);
          break;
        case "edit_attached_files":
          this.edit_attached_files_click(item);
          break;
        case "edit_tags":
          this.edit_tags_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async fillUpFilters() {
      // createdBy
      const users = await this.getUsers();
      if (!users) return;
      this.filters.find((p) => p.field == "createdBy").params.options =
        users.map((p) => ({ value: p._uri, text: p.name }));

      // reportTemplateTags
      const tags = await this.getTagsForEntity("template");
      if (!tags) return;
      this.filters.find((p) => p.field == "reportTemplateTags").params.options =
        tags
          .filter((p) => p.tag.startsWith("role:reportManager:template:group"))
          .map((p) => ({
            value: p.tag,
            text: this.shortenTag(p.tag).substring(6),
          }));

      // reportTemplateGroupTags
      this.filters.find(
        (p) => p.field === "reportTemplateGroupTags"
      ).params.options = tags
        .filter((p) => p.tag.startsWith("role:reportManager:template:abac"))
        .map((p) => ({
          value: p.tag,
          text: this.shortenTag(p.tag).substring(5),
        }));
    },
    async getTagCollection(entity, prefix) {
      if (!Object.keys(this.$store.state.tagCollection.template).length) {
        try {
          const { data } = await callAPI({
            url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/tag-collection/${entity}`,
            method: "GET",
          });
          const tagCollection = {};
          data.forEach((item) => {
            const tagType = item.split(prefix).pop().split(":").shift();
            if (!tagCollection[tagType]) {
              tagCollection[tagType] = [];
            }
            tagCollection[tagType].push(item);
          });
          this.$store.state.tagCollection.template = tagCollection;
        } catch (error) {
          this.errorSnackbar(error);
        }
      }
    },
    shortenTag(tag) {
      return tag.split("role:reportManager:template:").pop();
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.col-logo {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.v-data-table-header {
  text-transform: uppercase !important;
}
.mdi-file-excel {
  color: $accent !important;
}
.mdi-file-pdf {
  color: $red !important;
}
.mdi-file-code {
  color: $mOrange !important;
}
.bubble-button {
  color: $accent !important;
  background-color: $white !important;
}
.close-button {
  color: white !important;
  background-color: #696a6f !important;
}
</style>
