<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>
        {{
          formData && formData.name
            ? $t("edit_template") + " - " + formData.name
            : $t("add_template")
        }}
      </span>
      <v-spacer />
      <v-icon @click="$emit('cancel')">mdi-close</v-icon>
    </v-toolbar>

    <v-form ref="form" class="pa-6">
      <v-row>
        <v-text-field
          :label="$t('name')"
          v-model="formData.name"
          :rules="formRules.required"
          :autofocus="true"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-textarea
          :label="$t('description')"
          v-model="formData.description"
          :rules="formRules.optional"
          type="text"
          counter="1024"
          maxlength="1024"
          rows="3"
        ></v-textarea>
      </v-row>
      <v-row>
        <v-select
          :label="$t('datasourceId')"
          :value="formData.datasourceId"
          @change="datasourceId_onChange"
          :items="datasources"
          item-text="name"
          item-value="datasourceId"
          class="test-locator-datasourceId"
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
        >
          <template v-slot:item="{ item }">
            <span :label="item.name">{{ item.name }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <v-select
          :label="$t('datasetId')"
          v-model="formData.datasetId"
          :items="datasets"
          item-text="name"
          item-value="datasetId"
          class="test-locator-datasetId"
          :no-data-text="$t('no_data')"
          :rules="formRules.required"
          :loading="datasets_loading"
        >
          <template v-slot:item="{ item }">
            <span :label="item.name">{{ item.name }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <v-select
          :label="$t('format')"
          v-model="formData.format"
          :rules="formRules.required"
          :items="formats"
          class="test-locator-format"
          :no-data-text="$t('no_data')"
        >
          <template v-slot:item="{ item }">
            <span :label="item">{{ item }}</span>
          </template>
        </v-select>
      </v-row>
      <v-row>
        <GsObjectEditor
          :label="$t('inputParams')"
          v-model="formData.inputParams"
          :validator="validate_inputParams"
          :required="true"
        />
      </v-row>
      <v-row>
        <v-file-input
          :label="$t('template')"
          :value="templateFileName"
          accept=".jrxml"
          @change="templateFileName_change"
          :rules="formRules.required"
        ></v-file-input>
      </v-row>
      <v-row>
        <v-select
          :label="$t('status')"
          v-model="formData.status"
          :items="statuses"
          item-text="name"
          item-value="id"
          :no-data-text="$t('no_data')"
          :rules="formRules.optional"
        ></v-select>
      </v-row>
    </v-form>

    <!-- errors -->
    <div class="d-flex flex-row px-3" v-if="error">
      <span class="subtitle-1 mb-4 red--text">
        {{ $t("form_invalid_text") }}: {{ error }}
      </span>
    </div>

    <v-card-actions>
      <!-- <v-btn class="mr-2" color="default" @click="$emit('cancel')">
        {{ $t("close") }}
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save">
        {{ $t("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const enums = require("@/app-data/template-enums.json");
const notificationsEnums = require("@/app-data/notifications-enums.json");
const { callBffAPI, callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsObjectEditor from "@/components/GsObjectEditor";

export default {
  name: "GsTemplateEditor",
  components: {
    GsObjectEditor,
  },
  props: {},
  data() {
    return {
      editMode: false,
      formData: {
        name: null,
        description: null,
        datasourceId: null,
        datasetId: null,
        format: null,
        inputParams: null,
        template: null,
        status: null,
      },
      formRules: {
        required: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredIfCreate: [
          (value) =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true,
        ],
        requiredArray: [
          (value) =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true,
        ],
        optional: [(value) => true],
      },
      datasources: [],
      datasets: [],
      formats: enums.format,
      statuses: enums.status.map((p) => {
        return { id: p, name: this.$t(p.substr(p.lastIndexOf(".") + 1)) };
      }),
      templateFileName: null,
      valid: true,
      error: null,
      loading: false,
      datasets_loading: false,
    };
  },
  async created() {},
  methods: {
    async create() {
      this.editMode = false;
      this.clearForm();
      await this.getDatasources();
    },
    async edit(templateId) {
      this.editMode = true;
      // console.log("edit", templateId)

      this.clearForm();

      if (typeof templateId == "string") {
        this.formData = await this.getTemplate(templateId);
      } else {
        this.formData = JSON.parse(JSON.stringify(templateId));
      }

      this.templateFileName = this.formData.template
        ? new File([], "template.jrxml")
        : null;

      await this.getDatasources();
      await this.getDatasets();

      // console.log(this.formData)
    },
    async getDatasources() {
      // console.log("getDatasources")
      try {
        // const response = await callAPI({
        //   url: `${process.env.VUE_APP_BFF_ORIGIN}/datasources?page=0&limit=100&sort=name:asc`,
        //   method: "GET"
        // });

        this.datasources = [];

        const url = `/datasources?page=0&limit=100&sort=name:asc`;
        const response = await callBffAPI({ url, method: "GET" });

        this.datasources = response.data;
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async getDatasets() {
      // console.log("getDatasets", this.formData.datasourceId, this.formData)
      if (this.formData.datasourceId == null) {
        this.datasets = [];
        return;
      }

      const selectedDatasource = this.datasources.find(
        (item) => item.datasourceId === this.formData.datasourceId
      );
      // console.log(selectedDatasource)

      if (selectedDatasource == null) {
        this.datasets = [];
        return;
      }

      try {
        this.datasets_loading = true;
        this.datasets = [];

        const url = `${selectedDatasource.dataset}?page=0&limit=100&sort=name:asc`;
        const response = await callBffAPI({ url, method: "GET" });

        this.datasets = response.data;
      } catch (err) {
        // this.errorSnackbar(err);
      } finally {
        this.datasets_loading = false;
      }
    },
    async getTemplate(id) {
      try {
        // console.log("getTemplate")
        const result = await callBffAPI({
          url: `/templates/${id}`,
          method: "GET",
        });
        return result.data;
      } catch (err) {
        this.errorSnackbar(err);
      }
    },
    async save() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close"),
          },
        });
        return;
      }

      const url = this.formData.templateId
        ? `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.formData.templateId}`
        : `${process.env.VUE_APP_BFF_ORIGIN}/templates`;

      const method = this.formData.templateId ? "PATCH" : "POST";

      const data = {
        name: this.formData.name,
        datasourceId: this.formData.datasourceId,
        datasetId: this.formData.datasetId,
        format: this.formData.format,
        inputParams: this.formData.inputParams,
        template: this.formData.template,
      };

      data.description = this.formData.description
        ? this.formData.description
        : null;
      if (this.formData.status) data.status = this.formData.status;

      try {
        this.loading = true;
        const result = await callAPI({ url, method, data });
        this.$emit("saved", result.data);
        this.clearForm();
      } catch (err) {
        const data = err?.response?.data;
        // console.log(data);

        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.$refs.form.resetValidation();
      this.valid = true;
      this.formData = {
        name: null,
        description: null,
        datasourceId: null,
        datasetId: null,
        format: null,
        inputParams: {},
        template: null,
        status: notificationsEnums.notificationTemplateStatus,
      };
      this.error = null;
      this.templateFileName = null;
    },
    async datasourceId_onChange(value) {
      this.formData.datasourceId = value;
      await this.getDatasets();
    },
    templateFileName_change(v) {
      this.templateFileName = v;

      if (!this.templateFileName) {
        this.formData.template = "";
        return;
      }
      // Use the javascript reader object to load the contents of the file in the v-model prop
      const reader = new FileReader();
      reader.readAsText(this.templateFileName);
      reader.onload = () => {
        this.formData.template = reader.result;
      };
    },
    validate_inputParams(v) {
      const fields = Object.keys(v);

      // console.log("validate_inputParams", v)
      const allowedFields = [
        "assetId",
        "assetIds",
        "driverId",
        "driverIds",
        "geoZoneId",
        "geoZoneIds",
        "locale",
        "timezone",
        "startDate",
        "endDate",
        "calendar",
        "calendarType",
        "dailyTime",
        "normalWork",
        "effectiveWork",
      ];
      for (let field of fields) {
        if (!allowedFields.includes(field)) {
          // console.log("validate_inputParams", field)
          return this.$t("invalid_value");
        }
      }

      if (typeof v.locale === "undefined") {
        return this.$t("invalid_value");
      }

      if (typeof v.timezone === "undefined") {
        return this.$t("invalid_value");
      }

      const requiredAny = [
        "assetId",
        "assetIds",
        "driverId",
        "driverIds",
        "geoZoneId",
        "geoZoneIds",
      ];
      if (!requiredAny.some((p) => fields.includes(p))) {
        return this.$t("invalid_value");
      }

      // console.log("validate_inputParams", true)
      return true;
    },
  },
};
</script>
