<template>
  <v-select
    :items="visibleNotifications"
    item-value="notificationId"
    :value="value"
    @change="onChange"
    :no-data-text="$t('no_data')"
  >
    <template v-slot:selection="{ item }">
      <span>{{ item.channelType }} : {{ item.templateType }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.channelType }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.templateType }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const notificationsEnums = require("@/app-data/notifications-enums.json");
import dayjs from "dayjs";

export default {
  name: "AvailableNotificationNotificationsDropdown",
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    filter: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      notifications: [],
      totalItems: 0,
      selectedNotification: null,
    };
  },
  computed: {
    visibleNotifications() {
      return this.notifications.filter(this.filter);
    },
  },
  watch: {},
  async created() {
    await this.getNotifications();
  },
  methods: {
    async getNotifications() {
      try {
        this.$store.state.loading = true;
        let url = `${process.env.VUE_APP_BFF_ORIGIN}/notifications?page=0&limit=10&sort=templateType:asc`;
        // url += "&r8sFields=createdBy.name,updatedBy.name"

        const result = await callAPI({
          url,
          method: "GET",
        });
        this.notifications = [];
        for (const notification of result.data) {
          await this.calculateProperties(notification);
          this.notifications.push(notification);
        }
        this.totalItems = parseInt(result.headers["x-total-count"], 10);
        // console.log(this.notifications)
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async calculateProperties(notification) {
      notification.$calculated = notification.$calculated || {};
    },
    onChange(value) {
      const selectedNotification = this.notifications.find(
        (p) => p.notificationId === value
      );
      this.$emit("input", selectedNotification);
    },
  },
};
</script>
