<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t("title") }}
        </v-card-title>

        <!-- <v-card-text>
				</v-card-text> -->
        <div v-for="(item, index) in editValue" :key="index">
          <h3>{{ item.title }}</h3>
          <p>{{ item.paragraph }}</p>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="visible = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "GsHelpBubble",
  props: {},
  data() {
    return {
      visible: false,
      editValue: null,
    };
  },
  created() {},
  methods: {
    edit(editValue) {
      this.editValue = editValue;
      this.visible = true;
    },
  },
};
</script>
