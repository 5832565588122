<template>
  <v-card>
    <v-toolbar color="primary" dark
      >{{ $t("edit_tags") }} -
      {{ $t(`template_name.${templateName}`) }}</v-toolbar
    >
    <div class="ma-4" v-if="templateId">
      <!-- abac -->
      <h3>{{ $t("templateTags.abac") }}</h3>
      <div v-if="actualTags.abac && actualTags.abac.length">
        <div
          :key="index"
          v-for="(tag, index) in actualTags.abac"
          class="d-flex mb-2"
        >
          <span class="mr-4">{{ tag }}</span>
          <v-icon small @click="deleteTag(tag)">mdi-delete</v-icon>
        </div>
      </div>
      <div v-else>
        {{ $t("no_result") }}
      </div>
      <div
        class="mt-2 d-flex align-center"
        v-if="tagCollection.abac && tagCollection.abac.length"
      >
        <v-select
          :items="forAbacDropdown"
          :label="$t('addNewTag')"
          v-model="selectedForAbac"
        ></v-select>
        <v-btn @click="addTag('abac')" class="ml-4">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <!-- group -->
      <h3 class="mt-4">{{ $t("templateTags.group") }}</h3>
      <div v-if="actualTags.group && actualTags.group.length">
        <div
          :key="index"
          v-for="(tag, index) in actualTags.group"
          class="d-flex mb-2"
        >
          <span class="mr-4">{{ tag }}</span>
          <v-icon small @click="deleteTag(tag)">mdi-delete</v-icon>
        </div>
      </div>
      <div v-else>
        {{ $t("no_result") }}
      </div>
      <div
        class="mt-2 d-flex align-center"
        v-if="tagCollection.group && tagCollection.group.length"
      >
        <v-select
          :items="forGroupDropdown"
          :label="$t('addNewTag')"
          v-model="selectedForGroup"
        ></v-select>
        <v-btn @click="addTag('group')" class="ml-4">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="ma-4" v-else>
      {{ $t("no_result") }}
    </div>
    <v-card-actions>
      <v-spacer />
      <v-btn color="mb-3 close-button px-4" @click="onClose">{{
        $t("close")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTemplateTags",
  props: {
    templateId: {
      type: String,
      default: null,
    },
    templateName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      actualTags: {},
      selectedForGroup: null,
      selectedForAbac: null,
      modified: false,
    };
  },
  computed: {
    tagCollection() {
      return this.$store.state.tagCollection.template;
    },
    forAbacDropdown() {
      if (this.actualTags.abac) {
        return this.tagCollection.abac.filter(
          (item) => this.actualTags.abac.indexOf(item) === -1
        );
      }
      return this.tagCollection.abac;
    },
    forGroupDropdown() {
      if (this.actualTags.group) {
        return this.tagCollection.group.filter(
          (item) => this.actualTags.group.indexOf(item) === -1
        );
      }
      return this.tagCollection.group;
    },
  },
  watch: {
    templateId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTags("role:reportManager:template:");
        }
      },
    },
  },
  mounted() {
    this.modified = false;
  },
  methods: {
    async getTags(prefix) {
      try {
        const { data } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/tags`,
          method: "GET",
        });
        this.actualTags = {};
        data.forEach((item) => {
          // console.log(item);
          const tagType = item.split(prefix).pop().split(":").shift();
          if (!this.actualTags[tagType]) {
            this.actualTags[tagType] = [];
          }
          this.actualTags[tagType].push(item);
        });
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async deleteTag(tag) {
      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/tags/${tag}`,
          method: "DELETE",
        });
        this.modified = true;
        this.getTags("role:reportManager:template:");
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async addTag(type) {
      var tagToAdd = "";
      if (type === "abac") {
        tagToAdd = this.selectedForAbac;
      } else if (type === "group") {
        tagToAdd = this.selectedForGroup;
      }

      if (!tagToAdd) {
        return;
      }

      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/tags`,
          method: "POST",
          data: {
            tag: tagToAdd,
          },
        });

        if (type === "abac") {
          this.selectedForAbac = null;
        } else if (type === "group") {
          this.selectedForGroup = null;
        }
        this.modified = true;
        this.getTags("role:reportManager:template:");
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    onClose() {
      if (this.modified) this.$emit("saved");
      else this.$emit("cancel");
    },
  },
};
</script>
