<template>
  <div class="d-flex">
    <div class="break-all">{{ showChecksum(checksum) }}</div>
    <div @click="changeViewMode" class="cursor-pointer ml-1">
      <v-icon v-if="showFullString" small>mdi-chevron-left-box-outline</v-icon>
      <v-icon v-else small>mdi-chevron-right-box-outline</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "GsChecksum",
  props: {
    checksum: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showFullString: false,
    };
  },
  methods: {
    changeViewMode() {
      this.showFullString = !this.showFullString;
    },
    showChecksum(checksum) {
      if (!checksum) {
        return "-";
      }

      return this.showFullString ? checksum : `${checksum.slice(0, 7)}...`;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.break-all {
  word-break: break-all;
}
</style>
