<template>
  <v-container fluid>
    <v-card flat>
      <span>{{ $t("select_a_notification_to_attach") }}:</span>
      <div class="d-flex flex-row">
        <GsAvailableNotifications
          v-model="selectedNotification"
          :filter="templateNotificationFilter"
        />
        <v-btn
          class="ml-2 mt-2"
          color="primary"
          @click="addNotification"
          :disabled="selectedNotification == null"
        >
          <v-icon left small>mdi mdi-plus</v-icon>
          {{ $t("add") }}
        </v-btn>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="templateNotifications"
        :footer-props="{
          itemsPerPageText: $t('rows_per_page'),
          pageText: '{0}-{1} / {2}',
        }"
        :loading-text="$t('loading_data')"
        :no-data-text="$t('no_data')"
        :no-results-text="$t('no_result')"
      >
        <template v-slot:item.templateType="{ item }">
          <div>
            <span>{{ item.$calculated.notification.templateType }}</span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            color="error"
            @click="removeTemplateNotification(item)"
            >mdi mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
const enums = require("@/app-data/template-enums.json");
const notificationsEnums = require("@/app-data/notifications-enums.json");
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsAvailableNotifications from "@/components/GsAvailableNotifications";

export default {
  name: "GsTemplateNotifications",
  components: {
    GsAvailableNotifications,
  },
  props: {
    templateId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      templateNotifications: [],
      selectedNotification: null,
      headers: [
        {
          text: this.$t("templateType"),
          align: "start",
          value: "templateType",
        },
        {
          text: "",
          width: "100px",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    locales() {
      return this.editValue ? this.editValue.locales : [];
    },
  },
  watch: {
    templateId: {
      immediate: true,
      deep: true,
      handler(value) {
        this.getTemplateNotifications();
      },
    },
  },
  async created() {},
  methods: {
    async getTemplateNotifications() {
      if (this.templateId == null) {
        this.templateNotification = [];
        this.totalItems = 0;
        return;
      }
      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/notifications`;
        const result = await callAPI({
          url,
          method: "GET",
        });
        // console.log(result.data)
        this.templateNotifications = [];
        for (const templateNotification of result.data) {
          await this.calculateProperties(templateNotification);
          this.templateNotifications.push(templateNotification);
        }
        this.totalItems = parseInt(result.headers["x-total-count"], 10);
        // console.log(this.templateNotifications)
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async calculateProperties(templateNotification) {
      templateNotification.$calculated = templateNotification.$calculated || {};
      templateNotification.$calculated.notification =
        await this.getNotification(templateNotification.notificationId);
    },
    async getNotification(notificationId) {
      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/notifications/${notificationId}`;
        const result = await callAPI({
          url,
          method: "GET",
        });
        return result.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async addNotification() {
      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/notifications`;
        const result = await callAPI({
          url,
          method: "POST",
          data: {
            notificationId: this.selectedNotification.notificationId,
          },
        });
        await this.getTemplateNotifications();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async removeTemplateNotification(notification) {
      const confirmResult = await this.$dialog.confirm({
        text: this.$t("delete_modal"),
        title: this.$t("confirmation"),
        actions: {
          false: {
            text: this.$t("no"),
            color: "primary",
          },
          true: {
            text: this.$t("yes"),
            color: "default",
          },
        },
      });
      if (!confirmResult) return;

      try {
        this.$store.state.loading = true;
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${notification.templateId}/notifications/${notification.notificationId}`;
        const result = await callAPI({
          url,
          method: "DELETE",
        });
        await this.getTemplateNotifications();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    templateNotificationFilter(template) {
      // console.log(this.templateNotifications, template)
      return !this.templateNotifications.some(
        (p) => p.notificationId === template.notificationId
      );
    },
  },
};
</script>
