<template>
  <v-container fluid>
    <v-card flat>
      <span>{{ $t("select_a_file_to_attach") }}:</span>
      <div class="d-flex flex-row">
        <v-select
          v-model="selectedFileId"
          :items="availableFiles"
          label=""
          item-value="fileId"
          :no-data-text="$t('no_data')"
        >
          <template v-slot:selection="{ item }">
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span>{{ item.title }} ({{ item.fileName }})</span>
          </template>
          <template v-slot:item="{ item }">
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span :label="item.fileName"
              >{{ item.title }} ({{ item.fileName }})</span
            >
          </template>
        </v-select>
        <v-btn
          class="ml-2 mt-2"
          color="primary"
          @click="attachFile"
          :disabled="selectedFileId == null"
        >
          <v-icon left small>mdi mdi-plus</v-icon>
          {{ $t("attach") }}
        </v-btn>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="attachedFiles"
        :footer-props="{
          itemsPerPageText: $t('rows_per_page'),
          pageText: '{0}-{1} / {2}',
        }"
        :loading-text="$t('loading_data')"
        :no-data-text="$t('no_data')"
        :no-results-text="$t('no_result')"
      >
        <template v-slot:item.fileName="{ item }">
          <div>
            <v-avatar size="16px" class="mx-2">
              <v-img :src="item.$calculated.icon" contain />
            </v-avatar>
            <span>{{ item.title }} ({{ item.fileName }})</span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" color="error" @click="deAttachFile(item)"
            >mdi mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsTemplateFilesEditor",
  data() {
    return {
      templateId: null,
      files: [],
      attachedFiles: [],
      selectedFileId: null,
      headers: [
        {
          text: this.$t("fileName"),
          align: "start",
          value: "fileName",
          sortable: true,
        },
        {
          text: "",
          width: "100px",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    availableFiles() {
      const attachedFileIds = this.attachedFiles.map((p) => p.fileId);
      return this.files.filter((p) => !attachedFileIds.includes(p.fileId));
    },
  },
  methods: {
    async edit(templateId) {
      this.templateId =
        typeof templateId == "string" ? templateId : templateId.templateId;
      await this.getFiles();
      await this.getAttachedFiles();
    },
    async getFiles() {
      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/files?limit=100`;
        const result = await callAPI({
          url,
          method: "GET",
        });
        this.files = [];
        for (const file of result.data) {
          await this.calculateProperties(file);
          this.files.push(file);
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async getAttachedFiles() {
      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/files`;
        const result = await callAPI({
          url,
          method: "GET",
        });
        const attachedFileIds = result.data.map((p) => p.fileId);
        this.attachedFiles = this.files.filter((p) =>
          attachedFileIds.includes(p.fileId)
        );
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async calculateProperties(file) {
      if (!file.fileName) {
        return;
      }
      file.$calculated = file.$calculated || {};
      file.$calculated.fileNameExt = file.fileName.substr(
        file.fileName.lastIndexOf(".") + 1
      );
      try {
        file.$calculated.icon = require(`@/assets/file-type-icons/${file.$calculated.fileNameExt}.svg`);
      } catch {
        file.$calculated.icon = require("@/assets/file-type-icons/unknown.svg");
      }
    },
    async attachFile() {
      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/files`;
        await callAPI({
          url,
          method: "POST",
          data: {
            fileId: this.selectedFileId,
          },
        });
        await this.getAttachedFiles();
        this.selectedFileId = null;
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async deAttachFile(file) {
      const confirmResult = await this.$dialog.confirm({
        text: this.$t("delete_modal"),
        title: this.$t("confirmation"),
        actions: {
          false: {
            text: this.$t("no"),
            color: "primary",
          },
          true: {
            text: this.$t("yes"),
            color: "default",
          },
        },
      });
      if (!confirmResult) return;

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/templates/${this.templateId}/files/${file.fileId}`;
        await callAPI({
          url,
          method: "DELETE",
        });
        await this.getAttachedFiles();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
  },
};
</script>
